@import "font";

.screenDisplay {
  will-change: auto;
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow: auto;

  background-color: #f37575;

  &-container {
    display: flex;
    max-width: 1700px;
    margin-top: 20px;
    padding-left: 48px;
    margin-left: auto;
    margin-right: auto;
  }

  &-content {
    // position: relative;
    display: flex;
    // min-width: 600px;
    width: 70%;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 48px;
    & img {
    }
  }

  &-room {
    @include font-h1;
  }
  &-heading {
    @include font-h2;
    // max-width: 60%;
  }
  &-text {
    @include font-p1;
  }

  &-under {
    display: flex;
  }

  &-more {
    margin-left: auto;
    display: inline-flex;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 24px;
    @include font-p1;
    color: white;
  }

  &-close {
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer;
    width: 32px;
  }
}

.videoContainer {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.vimeoVideo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
