@import "font";

.loadingView {
  will-change: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;

  background: black;

  display: flex;
  align-items: center;

  z-index: 10;
  width: 100vw;
  overflow: hidden;

  &-center {
    width: 100vw;
  }

  &-content {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);

    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-logo {
      width: 120px;
      margin-bottom: 24px;
    }

    &-title {
      @include font-h1-italic;
      color: white;
      margin-bottom: 12px;
    }
    &-counter {
      @include font-p1;
      color: white;
      margin-top: 8px;
    }

    &-text {
      @include font-p1;
      color: white;
    }

    &-progress {
      height: 20px;
      width: 480px;
    }
  }
}
