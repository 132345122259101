@import "font";

.about {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background: black;

  display: flex;
  align-items: center;

  &-center {
    width: 100vw;
  }

  &-close {
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer;
    width: 32px;
  }

  &-player {
  }

  &-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-logo {
      width: 120px;
    }

    &-title {
      @include font-h1-italic;
      color: white;
      margin-bottom: 32px;
    }

    &-text {
      @include font-p2;
      color: white;
      columns: 2;
      max-width: 800px;
    }

    &-progress {
      height: 20px;
      width: 500px;
    }
  }
}
