@import "font";

.mobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background: black;

  display: flex;
  align-items: center;

  z-index: 10;
  width: 100vw;
  overflow: hidden;

  &-center {
    width: 100vw;
    height: 100vh;
  }

  &-player {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }

  &-content {
    position: relative;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    height: 80%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-logo {
      width: 120px;
      margin-bottom: 24px;
    }

    &-title {
      @include font-h1-italic;
      color: white;
      margin-bottom: 12px;
      padding-left: 48px;
      padding-right: 48px;
      text-align: center;
    }
    &-counter {
      @include font-p1;
      color: white;
      margin-top: 8px;
    }

    &-text {
      @include font-p1;
      position: absolute;
      bottom: 16px;
      color: white;
      padding-left: 48px;
      padding-right: 48px;
      text-align: center;
    }

    &-progress {
      height: 20px;
      width: 480px;
    }
  }
}
