@mixin font-h1 {
  font-family: "Inter";
  font-weight: 800;
  font-size: 36px;
  margin-top: 16px;
  margin-bottom: 16px;
}

@mixin font-h2 {
  font-family: "Inter";
  font-weight: bold;
  font-size: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
}

@mixin font-p1 {
  font-family: "Inter";
  font-size: 19px;
}

@mixin font-p2 {
  font-family: "Inter";
  font-size: 15px;
}

@mixin font-h1-italic {
  font-family: "Libre Baskerville";
  font-style: italic;
  font-weight: 300;
  font-size: 24px;
  letter-spacing: -0.5px;
}
