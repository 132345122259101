@import "font";

.artistView {
  will-change: auto;
  background-color: #f37575;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100vw;
  min-height: 100vh;

  overflow: auto;

  &-container {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;

    padding-left: 48px;
    padding-right: 48px;
    padding-bottom: 200px;
  }

  &-heading {
    @include font-h1;
    text-transform: uppercase;
  }

  &-grid {
    display: flex;
    flex-direction: column;

    &-row {
      display: flex;
      justify-content: space-between;
      &:not(:first-child) {
        margin-top: 48px;
      }
    }
  }

  &-artist {
    width: 30%;

    & img {
      width: 100%;
    }

    & p {
      padding: 16px;
      background: black;
      color: white;
    }
  }

  &-screen {
    will-change: auto;
    width: 60%;
    padding-bottom: 48px;

    & h2 {
      @include font-h2;
      margin-top: 8px;
    }

    & p {
      @include font-p1;
    }

    &-right {
      margin-left: auto;
    }
  }

  &-comments {
    cursor: pointer;
    float: right;
    margin-top: 8px;
    display: flex;
    & p {
      cursor: pointer;
      font-weight: bold;
      @include font-p2;
    }
    &-bubble {
      cursor: pointer;
      margin-left: 8px;
      width: 26px;
    }
  }

  &-header {
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-logo {
      height: 100%;
    }
    &-close {
      position: fixed;
      top: 36px;
      right: 36px;
      cursor: pointer;
      width: 32px;
    }
  }
}
